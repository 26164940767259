<footer>
    <div class="content">
        <div class="row">
            <div class="col-md-6 pl-lg-0 pr-lg-0 mb-3 mb-lg-0">
                <div class="row">
                    <div class="col-lg-8 pl-lg-0 pr-lg-0 mb-4 mb-lg-0">
                        <h6 i18n="@@head-office">Head Office</h6>
                        <div class="address">
                            <span i18n="@@gonderal-address">GönderAl Ödeme Hizmetleri A.Ş.<br>
                                Hamidiye Mah. Selçuklu Cad. Vadipark A Blok No:10G 34408 Kağıthane, İstanbul</span>
                        </div>
                    </div>
                    <div class="col-lg-4 pl-lg-0 pl-lg-3">
                        <h6 i18n="@@contact">Contact</h6>
                        <div class="contacts">
                            <div>
                                <span></span>
                                <span i18n="@@gonderal-phone">+90 850 242 4442</span>
                            </div>
                            <div>
                                <span></span>
                                <span>info@gonder-al.com</span>
                            </div>
                            <!-- <div>
                                <span></span>
                                <span>www.gonder-al.com</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 pr-lg-0 footer-right">
                <div class="vertical-line"></div>
                <form [formGroup]="ftSubscribeForm"
                    (ngSubmit)="addSubscriber(ftSubscribeForm.value,ftSubscribeForm.valid)">
                    <h6 i18n="@@updates-news">Receive our News Updates</h6>
                    <!-- Start field+button -->
                    <div class="field-button d-flex flex-wrap flex-md-nowrap">
                        <input type="email" formControlName="email" name="email" i18n-placeholder
                            placeholder="Enter email address" class="field mb-2 mb-md-0" maxlength="50">
                        <input type="submit" value="Get" i18n-value class="buttonDefault button-red">
                    </div>
                    <!-- End field+button -->
                    <!--
                        <div id="footer-social">
                            <h6 i18n="@@follow">Follow</h6>
                            <div class="social">
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-facebook-f"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                            </div>
                        </div>
                        -->
                </form>
            </div>
        </div>
    </div>
</footer>
<div id="sub-footer">
    <div
        class="content d-flex flex-wrap flex-column flex-sm-row flex-md-nowrap justify-content-center justify-content-md-start align-items-center">
        <div><span>© GonderAL 2022</span></div>
        <div>
            <a i18n="@@terms-conditions" href="#" data-toggle="modal" data-target="#tnc-modal">Terms &amp;
                Conditions</a>
            </div>
            <div>
                <a i18n="@@informationsocietyservices" href="bilgitoplumuhizmetleri">Information Society Services
                </a>
            </div>
    </div>
</div>
<!-- Start T&C Modal -->
<div class="modal fade" id="tnc-modal" tabindex="-1" role="dialog" aria-labelledby="Е7С" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h3>Gönder-al Web Sitesi Kullanım Koşulları</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <!-- <span i18n="@@close">Close</span> -->
                    <span aria-hidden="true">
                        <img src="./assets/img/cross.png" alt="Close" i18n-title title="Close">
                    </span>
                </button>
            </div>

            <div class="modal-body">

                <p>
                    Gönder-al web sitesini (“ Web Sitesi ”) ziyaret ettiğiniz için teşekkür ederiz. Bu Web Sitesi’nin
                    sahibi GönderAL Ödeme Hizmetleri Anonim Şirketi (“ Gönder-al ”) olup, Web Sitesi üzerinde her türlü
                    organizasyon, kullanım ve tasarruf yetkisi Gönder-al’a aittir.
                </p>
                <p>
                    Lütfen bu Web Sitesi’ni kullanmaya başlamadan önce Web Sitesi’nde mevcut bilgi, materyal, ürün ve
                    hizmetlere ilişkin aşağıda yazılı kullanım koşullarını dikkatlice okuyunuz. Aşağıda belirtilen Web
                    Sitesi kullanım koşulları (“ Kullanım Koşulları ”), bu Web Sitesi’ne erişiminizi ve bu Web Sitesi’ni
                    kullanımınızı düzenler. Bu Web Sitesi’ne erişen ya da bu Web Sitesi’ni kullanmaya başlayan ve bu Web
                    Sitesi’nde sunulan hizmetlerden yararlanan her gerçek ve tüzel kişi (“Kullanıcı ”) aşağıdaki
                    Kullanım Koşulları’nı kayıtsız, şartsız ve gayri kabili rücu olarak kabul etmiş sayılmaktadır.
                </p>
                <h5>1. Kullanım Koşulları’nın Değiştirilmesi</h5>
                <p>
                    Gönder-al’ın, Kullanım Koşulları’nı herhangi bir sebep göstermeksizin ve önce veya sonra ihbarda
                    bulunmaksızın zaman zaman güncelleme, silme, iyileştirme veya ilavede bulunma hakkı ile Web
                    Sitesi’nde bulunan materyalleri devretme hakkı saklıdır. Güncellenen yeni versiyonlar Gönder-al Web
                    Sitesi’nde yayımlanacaktır ve yayım tarihinden itibaren yürürlüğe girecektir. Kullanıcı, Gönder-al
                    Web Sitesi’ni kullanılırken, Kullanım Koşulları’nın güncellenip güncellenmediğini veya değiştirilip
                    değiştirilmediğini düzenli olarak kontrol etmekle yükümlüdür.
                </p>

                <h5>2. Kullanıcı Uygunluğu</h5>
                <p>
                    Web Sitesi, Gönder-al tarafından sunulur ve sadece yürürlükteki mevzuat uyarınca bağlayıcı
                    sözleşme(ler) yapabilen gerçek ve tüzel kişiler tarafından kullanılabilir. Kullanıcı, Web Sitesi’ni
                    kullanarak, Web Sitesi’nin kullanımı için geçerli olacak ilgili kanunlar kapsamında bu Kullanım
                    Koşulları da dahil ancak bununla sınırlı olmamak kaydıyla bağlayıcı sözleşme akdetme ve Web
                    Sitesi’ni bu Kullanım Koşulları’na uygun olarak kullanma konusunda yasal hak ve yetkiye sahip bir
                    yetişkin olduğunu kabul ve taahhüt etmiş olmaktadır. Bu şartları sağlamadığı takdirde, Kullanıcı’nın
                    Web Sitesi’ni kullanmasına izin verilmez.
                </p>

                <h5>3. Kullanım Koşulları Kapsamı</h5>
                <p>
                    Bu Web Sitesi, 6493 sayılı Ödeme ve Menkul Kıymet Mutabakat Sistemleri, Ödeme Hizmetleri ve
                    Elektronik Para Kuruluşları Hakkında Kanun çerçevesinde yurt içi ve yurt dışı para
                    transferleri gibi ödeme hizmetleri sunan ve bir ödeme kuruluşu olarak faaliyet gösteren Gönder-al
                    tarafından, Kullanıcı’nın bu hizmetleri kullanmasını sağlamak amacıyla sunulmaktadır. Kullanıcı, Web
                    Sitesi’ni sadece Kullanım Koşulları’na uygun olarak ve yukarıda açıklanan, yasal olarak izin verilen
                    amaçlar doğrultusunda kullanabilir.
                </p>

                <h5>4. Kullanım Koşulları Sınırlamaları</h5>
                <p>
                    Kullanıcı, bu Web Sitesi’nde sunulan hizmetlerden yararlanarak özellikle aşağıdaki sınırlamaları
                    kayıtsız, şartsız ve gayri kabili rücu olarak kabul etmektedir:
                </p>
                <ul>
                    <li>a. Web Sitesi başkalarının mülkiyet haklarının ihlaline yol açacak şekilde kullanılamaz.</li>
                    <li>b. Web Sitesi’nin çalışmasına engel olabilecek ve zarar verebilecek hiçbir cihaz, mekanizma,
                        yazılım veya sair komut dizileri kullanılamaz. Web Sitesi’ne veya herhangi bir sisteme, virüs,
                        trojan, solucan, zaman bombası, veya Web Sitesi’nin içeriğindeki bilgilere zarar vermeyi,
                        bunlarla çakışmayı, bunları kesmeyi veya bunlardaki mülkiyeti kaldırmayı amaçlayan veya
                        başkalarının (aşağıda tarif edildiği gibi) fikri mülkiyet haklarını ihlal eden diğer herhangi
                        bir bilgisayar programlama rutini sokulamaz veya yüklenemez.</li>
                    <li>c. Web Sitesi altyapısı üzerinde makul olmayan veya aşırı yük oluşturabilecek hiçbir işlem
                        gerçekleştirilemez.</li>
                    <li>d. Gönder-al tarafından oluşturulan içerikler bloke edilemez, üzerine yazılamaz veya
                        değiştirilemez ya da Web Sitesi herhangi bir şekilde engellenemez.</li>
                    <li>e. Web Sitesi’ndeki bilgiler ticari amaçlarla kopyalanamaz. Özellikle Web Sitesi’nden çıkarılmış
                        içerikleri "robotlar", diğer arama motoru teknolojileri veya diğer otomatik mekanizmalar yardım
                        veya aracılığıyla kopyalanamaz, dağıtılamaz ya da başka şekilde kullanılamaz veya çoğaltılamaz.
                    </li>
                    <li>f. Her ne şekilde olursa olsun, Gönder-al’ın ağlarına izinsiz erişim sağlanamaz veya buna
                        teşebbüs edilemez.</li>
                    <li>g. Başta üçüncü şahıslara ait kredi kartı veya banka hesap bilgileri ile bu şahısların
                        muvafakati olmaksızın yapılan işlemler olmak üzere izinsiz, sahte veya hileli ödeme ve
                        işlemlerde bulunulamaz.</li>
                    <li>h. Web Sitesi’nden aktarılan bilgilerin kaynağı gizlenemez.</li>
                    <li>i. Web Sitesi’ne yanlış veya yanıltıcı bilgi yerleştirilemez.</li>
                    <li>j. Gönder-al’ın açık ve yazılı izni olmaksızın, içerik veya bilgilerin herhangi bir kısmı veya
                        yönü çerçevelenemez veya bunları kapsamak üzere herhangi bir çerçeveleme tekniği kullanılamaz.
                    </li>
                </ul>

                <h5>5. Lisans ve Fikri Mülkiyet Hakları</h5>
                <p>
                    23 Temmuz 2015 tarihinde Bankacılık Düzenleme ve Denetleme Kurumu'ndan para transferine
                    ilişkin olarak alınan lisans ile Web Sitesi ve içeriklerinde bulunan her türlü materyalde
                    yer alan özel grafikler, simgeler, işaretler, logo, marka ve telif hakları da dahil olmak üzere tüm
                    fikri mülkiyet hakları Gönder-al’a ait olup kanun koruması altındadır. Bunların tamamı veya bir
                    kısmı, Gönder-al’ın açık ve yazılı izni olmaksızın hiçbir şekilde kopyalanamaz, müdahale edilemez ve
                    taklit edilemez.
                </p>
                <p>
                    Bu Kullanım Koşulları tarafından açıkça yetki verilmediği sürece veya Gönder-al’ın yazılı ön izni
                    alınmaksızın, Web Sitesi’nin Gönder-al’ın fikri mülkiyet hakkına tabi içeriği hiçbir şekilde
                    kopyalanamaz, çoğaltılamaz, değiştirilemez, kiralanamaz, görüntülenemez, gerçekleştirilemez,
                    lisanslanamaz, yeniden yayınlanamaz, gönderilemez, aktarılamaz, satılamaz, dağıtılamaz,
                    araştırılamaz, iyileştirilemez, izlenemez, alınamaz, ödünç verilemez, bunlardan eser türetilemez
                    veya karşıya yüklenemez. Burada açıkça belirtildiği durumlar hariç olmak üzere, Gönder-al,
                    Kullanıcı’ya Gönder-al’ın veya herhangi bir üçüncü kişinin fikri mülkiyet haklarına ilişkin açık ya
                    da kapalı hiçbir hak vermez.
                </p>

                <h5>6. Diğer Sitelere Bağlantılar</h5>
                <ul>
                    <li>a. Giden Bağlantılar: Web Sitesi, üçüncü kişilerin web sitelerine ve kaynaklarına giden
                        bağlantılar içerebilir (bunlara toplu olarak “ Bağlantılı Siteler” denir). Bu Bağlantılı Siteler
                        ancak ve ancak size kolaylık olsun diye sunulmuştur veya sunulacaktır. Bu, Gönder-al'ın bu tür
                        Bağlantılı Siteler’in içeriğini onayladığı anlamına gelmez. Gönder-al, herhangi bir Bağlantılı
                        Site’deki hiçbir içerik, yazılım, hizmet veya uygulamanın doğruluğu, kesinliği, performansı veya
                        kalitesi konusunda hiçbir teminat veya garanti sunmaz. Gönder-al, Bağlantılı Siteler’in
                        kullanılabilirliğinden veya bu sitelerin içerik ya da faaliyetlerinden sorumlu değildir.
                        Bağlantılı Siteler’e erişmeye karar verdiğinizde, bunun riski tamamen Kullanıcı’ya aittir.
                        Kullanıcı’nın, Bağlantılı Siteleri kullanımı, Bağlantılı Site’nin gizlilik politikası da dahil
                        olmak üzere, ancak bununla sınırlı olmaksızın, geçerli her türlü politika ve kullanım hüküm ve
                        koşullarına tabi olacaktır.</li>
                    <li>b. Gelen Bağlantılar: Gönder-al ile yapılan ayrı bir bağlantı sözleşmesi olmadan, Web Sitesi’nin
                        https://gonder-al.com adresi dışındaki herhangi bir sayfasına düz bir metin bağlantısıyla
                        bağlantı vermek kesinlikle yasaktır. https://gonder-al.com/yeni/index.aspxadresine veya bunun
                        içindeki herhangi bir sayfaya bağlantı kuran herhangi bir web sitesinin veya diğer aygıtın i)
                        içeriği kopyalaması, ii) içeriğin etrafında bir tarayıcı veya kenarlık ortamı kullanması, iii)
                        Gönder-al’ın onayladığını herhangi bir şekilde ima etmesi, iv) Gönder-al’a ilişkin gerçeklerin
                        yanlış aktarılması, v) Gönder-al’ın hizmetleriyle ilgili yanlış bilgiler sunması ve vi)
                        Gönder-al’dan açık ve yazılı izin almadan Gönder-al’ın herhangi bir logosu, işareti, markası
                        dahil herhangi bir fikri mülkiyet ürününü kullanması yasaktır.</li>
                </ul>

                <h5>7. Sonlandırma</h5>
                <p>
                    Gönder-al’ın, kararı kendisine ait olmak üzere; erişim ve kullanım hakkı başkaları için devam etse
                    bile, Kullanıcı’nın Web Sitesi’ni, ödeme hizmetlerini, bilgileri, sair hizmetleri ve içeriği
                    kullanımının istediği zaman ve kararı kendisine ait olmak üzere istediği herhangi bir nedenle sona
                    erdirilebileceğini veya askıya alınabileceği kabul edilmektedir. Bu tür bir askıya alma veya sona
                    erdirme durumunda Kullanıcı’nın derhal i) Web Sitesi’ni kullanmayı bırakması ve ii) içeriğin
                    herhangi bir kısmından yapılan kopyalar varsa, bunların tamamını imha etmesi gerekir. Bu tür bir
                    fesihten, askıya almadan veya sona erdirmeden sonra Web Sitesi’ne, Gönder-al’a ait ödeme
                    sistemlerine, bilgilere veya hizmetlere erişmek, bir istismar eylemi olarak algılanacaktır. Ayrıca
                    Kullanıcı, Web Sitesi’ni kullanmakla, Gönder-al’ın sunduğu ödeme hizmetlerine, bilgilerine ve/veya
                    sair hizmetlerine olan erişimin feshedilmesi veya askıya alınmasından dolayı Kullanıcı’ya veya
                    herhangi bir üçüncü kişiye karşı hiçbir sorumluluk almayacağını kabul etmiş olur.
                </p>

                <h5>8. Garantilerin Feragatnamesi</h5>
                <p>
                    Gönder-al, web sitesini, ödeme hizmetlerini, bilgileri veya içeriği kullanma sonucu elde edilecek
                    olan sonuçlarla ilgili hiçbir teminat veya garanti sunmaz. Kullanıcı’nın yazılım ve materyallere Web
                    Sitesi üzerinden veya Web Sitesi aracılığıyla erişimi ve bunları kullanımına ilişkin risk sadece
                    Kullanıcı’nın kendisine aittir.
                </p>
                <p>
                    Web sitesi, para gönderme sistemleri, bilgiler, hizmetler ve içerik, "olduğu gibi"
                    sunulmuştur. Gönder-al, yürürlükteki mevzuat uyarınca ve tüm düzenlemelere uygun olarak faaliyet
                    göstermekte olup buna rağmen azami ve yasaların izin verdiği ölçüde, üçüncü kişilerin haklarının
                    ihlal edilmediği ve belli bir amaç için uygunluk garantileri de dahil olmak üzere, ancak bunlarla
                    sınırlı olmaksızın, açık veya gizli, yasal veya diğer türlü tüm garantilerden feragat eder.
                    Kullanıcı tarafından Web Sitesi’nden elde edilen hiçbir bilgi, Gönder-al tarafından bu kullanım
                    koşullarında açıkça beyan edilmeyen hiçbir garantiyi teşkil etmeyecektir. Kullanıcı, bu Kullanım
                    Koşulları’ndaki sorumluluk ve garantinin sınırlarının ve muafiyetlerinin adil ve makul olduğunu
                    kabul eder ve onaylar.
                </p>

                <h5>9. Tazminat</h5>
                <p>
                    Kullanıcı, Web Sitesi’nin kullanılmasının bir koşulu olarak Web Sitesi’nin kullanımından kaynaklanan
                    hak ve taleplerinden doğan tüm sorumluluk, masraf (yasal ücret ve giderler dahil ancak sadece
                    bunlarla sınırlı olmamak kaydıyla) ve hasar ve zararlara karşı Gönder-al’ı tazmin etmeyi ve
                    bunlardan beri kılmayı kabul etmektedir.
                </p>
                <p>
                    Gönder-al, hiçbir durumda, Kullanıcı’nın Web Sitesi’ne veya Web Sitesi’nde bulunan sistem ve
                    materyallere erişimi veya bunları kullanmasına ilişkin olarak sözleşme ihlali, haksız fiil veya
                    diğer nedenlerle doğrudan veya dolaylı hasarlardan sorumlu olmayacaktır. Ayrıca, Gönder-al,
                    Kullanıcı veya üçüncü kişiler tarafından gerçekleştirilebilecek Kullanım Koşulları ihlali, haksız
                    fiil, ihmal veya başka nedenlerle meydana gelebilecek aktarım kesintisi, aksaklık veya arızaya
                    yönelik hiçbir sorumluluk kabul etmemektedir. Kullanıcı, Web Sitesi’ne veya diğer bağlantılı web
                    sitelerine erişerek veya bunları kullanarak, olması durumundamahkeme masrafları ve Web Sitesi’ni
                    kullanımından/ziyaret edilmesinden kaynaklanabilecek diğer harcamalar dahil ancak sadece bunlarla
                    sınırlı olmamak kaydıyla Gönder-al’ı hasar, hak talebi ve sorumluluklardan muaf tutmayı ve beri
                    kılmayı kabul etmektedir.
                </p>
                <h5>10. Yetkili Mahkeme ve Dil</h5>
                <p>
                    Kullanıcı’nın, işbu Kullanım Koşulları, Web Sitesi’ni veya Web Sitesi’nde bulunan ya da Web
                    Sitesi’nden indirilen/erişilen materyaller ile sistemleri kullanımı ve bunlardan doğan veya bunlarla
                    bağlantılı ihtilaflar veya hak talepleri, Türkiye Cumhuriyeti kanunlarına tabi olacak ve yine aynı
                    kanunlara uygun olarak yorumlanacaktır. Kullanıcı, bu tür ihtilaf ve hak taleplerini İstanbul
                    Çağlayan Mahkemelerinin yargı yetkisine tevdi etmeyi kabul etmektedir. Hukuki davalar ilgili
                    mevzuatlar uyarınca Gönder-al, Kullanıcı’nın ikamet ettiği veya Web Sitesi’ni kullandığı ülkenin
                    mahkemelerine taşıma hakkını saklı tutar.
                </p>
                <p>
                    Kullanım Koşulları’nın, Türkçe’den başka dillere tercüme edildiği durumlarda, Kullanım Koşulları’nın
                    tercüme edildiği herhangi bir dildeki tercümesi arasında herhangi bir uyumsuzluk veya tutarsızlık
                    olması durumunda Kullanım Koşulları’nın Türkçe versiyonu geçerli olacaktır.
                </p>
                <h5>11. Bölünebilirlik</h5>
                <p>
                    Kullanıcı, bu Kullanım Koşulları’nı veya bu Kullanım Koşulları’na dayanan hiçbir çıkar, hak ya da
                    sorumluluğunuzu başkalarınadevredemez. Yetkili olan herhangi bir mahkeme tarafından bu Kullanım
                    Koşulları’nın herhangi bir hükmünün geçersiz olduğu tespit edildiği takdirde bu hükmün geçersizliği,
                    bu Kullanım Koşulları’nın kalan hükümlerinin geçerliliğini etkilemez ve bu Kullanım Koşulları’nın
                    yetkisi ve geçerliliği devam eder. Bu Kullanım Koşulları’ndan herhangi birinden vazgeçilmesi, diğer
                    hüküm veya koşullardan vazgeçildiği anlamına gelmez.
                </p>
                <h5>12. Tavsiye, Yorum ve Geri Bildirimler</h5>
                <p>
                    Kullanıcı’nın, Gönder-al’a ait belgelerin, materyallerin ve sistemlerin içeriğine ilişkin soru,
                    yorum, tavsiye gibi geri bildirim verileri de dahil olmak üzere bazı bilgileri içerecek şekilde,
                    yayınlanan bir belgeye yanıt veriyorsa bu bilgiler, gizli bilgi kabul edilemez. Gönder-al, bu
                    bilgilere ilişkin hiçbir yükümlülük kabul etmemektedir. Gönder-al, bu bilgileri sınırlandırma
                    olmaksızın çoğaltabilir, kullanabilir, ifşa edebilir ve başkalarına dağıtabilir.
                </p>
                <p>
                    Web Sitesi’ne veya başka şekilde bize e-posta yoluyla gönderilen iletişimler de gizli bilgi kabul
                    edilemez. Gönder-al bunları herhangi bir amaçla çoğaltma, yayınlama veya başka şekilde kullanma
                    hakkını saklı tutar. Herhangi bir fikir, icat, konsept, teknik veya uzmanlık bilgisi de dahil olmak
                    üzere bu iletişimlerin içindekiler Gönder-al tarafından ürün ve/veya hizmetlerin geliştirilmesi,
                    üretilmesi ve/veya pazarlanması da dahil olmak üzere herhangi bir amaçla kullanılabilir. Ayrıca, bu
                    bilgilerde bulunan herhangi bir fikir, konsept, uzmanlık bilgisi veya tekniği bu bilgileri içeren
                    hizmetlerin geliştirilmesi ve pazarlanması da dahil olmak üzere herhangi bir amaçla kullanılabilir.
                </p>
                <h5>13. Üyelik ve Ödeme Hizmeti İşlemleri</h5>
                <p>
                    Kullanıcı tarafından üyelik veya ödeme işlemi sırasında Ödeme Kuruluşu ile paylaşılan bilgiler,
                    ödeme hizmetinin sunulması ve buna ilişkin işlerin yönetilmesi amacıyla saklanmakta ve
                    kullanılmaktadır. Bu bilgiler, işlemin gerçekleştirilmesi için Ödeme Kuruluşu’nun iş ortakları /
                    muhabirleri kurumlar vb gibi işlemin tarafı olan kişilerle paylaşılabilir.
                </p>
                <p>
                    Kullanıcı tarafından Ödeme Kuruluşu’na bildirilen hassas ödeme verileri dışındaki bilgiler,
                    mevzuatın izin verdiği ölçüde ve Kullanıcı’nın sistemde izin vermesi kaydıyla, Ödeme Kuruluşu
                    tarafından tanıtım, reklam, kampanya, promosyon, duyuru, mesaj gönderme vb. gibi pazarlama
                    faaliyetleri kapsamında kullanılabilecektir.
                </p>
                <p>
                    Gönder-al, hassas ödeme verilerinin herhangi bir kısmının kamu alanına girmesini veya yetkisiz
                    kullanımını veya yetkisiz kişilere ifşasını önlemek için gerekli tedbirleri almayı ve gerekli özeni
                    göstermeyi taahhüt etmektedir. Ancak Gönder-al’ın gerekli bilgi güvenliği önlemlerini almasına
                    karşın web sitesine ve sisteme yapılan saldırılar sonucunda gizli bilgilerin zarar görmesi veya
                    üçüncü kişilerin eline geçmesi durumunda, Gönder-al’ın herhangi bir sorumluluğu olmayacaktır.
                </p>
                <p>
                    Kullanıcı, Gönder-al’ın usulsüzlük ve/veya dolandırıcılık şüphesi oluşması halinde, Kullanıcı’nın
                    bilgilerinin, adli ve/veya idari mercilerle ve/veya Resmi Kurumlar ile paylaşabileceğini kabul ve
                    beyan eder.
                </p>
                <p>
                    Kullanıcı, ilgili işlem ile ilgili bilgilerin (referans numarası, alıcı bilgileri vs.) korunmasına
                    ve üçüncü kişilerle paylaşılmamasına yönelik gerekli önlemleri almayı kabul ve beyan eder. Söz
                    konusu bilgilerin Kullanıcı tarafından korunmaması nedeniyle ya da Kullanıcı’nın kusurundan
                    kaynaklanan nedenlerle ödeme işleminin hileli veya yetkisiz olarak gerçekleşmesi halinde, Kullanıcı,
                    söz konusu işlemden kaynaklanan her türlü doğrudan ve/veya dolaylı zararın kendisine ait olacağını
                    kabul ve beyan eder.
                </p>
                <h5>14. Müşteri Hizmeti</h5>
                <p>
                    Web Sitesi, Kullanım Koşulları veya Gönder-al’a ilişkin başka bir hususta herhangi bir yorum, sorun,
                    şikayet veya "Bize Ulaşın" bölümünde verilen adres, irtibat numarası veya Form üzerinden
                    ulaştırılabilir.
                </p>




            </div>
        </div>
    </div>
    <!-- End T&C Modal -->
</div>
<message-box></message-box>