import { Component, OnInit } from '@angular/core';
import { ApiRequest, ServiceBase, ApiResponse } from 'src/app/base';
import { CompanyInfoModel } from '../_models/InformationSocietyServicesModel';


@Component({
    selector: 'informationsocietyservices',
    templateUrl: './informationsocietyservices.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase]    
})
export class InformationSocietyServicesComponent implements OnInit {
    company: CompanyInfoModel;

    constructor() {
      this.company = {
        CompanyType: 'A.Ş.',
        Mersis: '0622041430800011',
        TradeRegistryOffice: 'İSTANBUL TİCARET ODASI',
        TradeRegistryNumber: '532861',
        TradeName: 'GönderAl Ödeme Hizmetleri Anonim Şirketi',
        Address: 'GönderAl Ödeme Hizmetleri A.Ş. Hamidiye Mah. Selçuklu Cad. Vadipark A Blok No:10 D:2 K:3 34408 Kağıthane, İstanbul',
        CommittedCapitalAmount: '9.027.000',
        PaidCapitalAmount: '9.027.000',
        RegisteredCapitalCeiling: '9.069.000',
        RegistrationDate: '13.09.2004',
        TaxOffice: 'Kağıthane',
        TaxNumber: '6220414308',
        Sector: '829101',
        ContactInfo: '+90 850 242 4442 info@gonder-al.com',
        ChairmanofBoard: 'Fazıl Ahmet Taboğlu',
        BoardMember1: 'Elif Özbay',
        BoardMember2: 'Sezai Bekgöz',
        BoardMember3: 'YK Üyesi 3',
        BoardMember4: 'YK Üyesi 4',
        BoardMember5: '',
        BoardMember6: '',
        Period: '31.12.2023- 31.12.2024',
        AuditorName: 'Anil Yeminli Mali Müşavirlik Ve Bağimsiz Denetim Anonim Şirketi',
        AuditorAddress: 'Kartaltepe Mah.Alem Sok. Billursaray Apt.No.3 K.4 D.14 Bakırköy/İstanbul'
      };
    }
    ngOnInit() {
    }
   
}