<div class="top-slider">
    <div class="arrow-wrapper" [ngClass]="{'hide':activeSlide=='paypoints'}">
        <!-- <div class="arrow right"><img src="/assets/img/arrow-slider.png"></div> -->
        <!-- <div class="arrow left"><img src="/assets/img/arrow-slider.png"></div> -->
    </div>
    <div class="slides" [ngClass]="{'paypoints-slides':activeSlide=='paypoints'}">
        <div *ngIf="activeSlide=='home'" id="home" class="slide"
            style="background-image: url('/assets/img/HEROS/home.jpg');">
            <div class="content dt d-none d-lg-block">
                <div class="title" i18n="@@connect-share">Connect & Share...</div>
                <div class="sub-title" i18n="@@connect-share-with-us">With Us.</div>
                <div class="description" i18n="@@slide-we-make-transfer-easy">At one of our 400+ locations,<br> We make transfering money easy.</div>
                <div class="button" *ngIf="siteVariablesModel.language == 'EN'">
                    <a i18n="@@find-a-location" href="/pay-points" class="buttonDefault button-red size26">Find a location</a>
                </div>
                <!-- <div class="button" *ngIf="siteVariablesModel.language != 'EN'">
                    <a href="{{siteVariablesModel.turkishHref}}/money-transfer"
                        class="buttonDefault button-red size26">Para Gönder</a>
                </div> -->
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div class="title pr-2" i18n="@@connect-share">Connect & Share...</div>
                <div class="sub-title" i18n="@@connect-share-with-us">With Us.</div>
                <div class="description w-100" i18n="@@slide-we-make-transfer-easy">At one of our 500+ locations,<br> We make transfering money easy.</div>
                <div class="button w-100" *ngIf="siteVariablesModel.language == 'EN'">
                    <a i18n="@@find-a-location" href="/pay-points" class="buttonDefault button-red mt-4 size26">Find a
                        location</a>
                </div>
                <!-- <div class="button w-100" *ngIf="siteVariablesModel.language != 'EN'">
                    <a href="{{siteVariablesModel.turkishHref}}/money-transfer"
                        class="buttonDefault button-red mt-4 size26">Para Gönder</a>
                </div> -->
            </div>
        </div>
         <!-- menuupdate -->
        <!-- <div *ngIf="activeSlide=='mobileapp'||activeSlide=='home'" id="mobileapp" class="slide"
            style="background-image: url('/assets/img/HEROS/app.jpg');">
            <div class="content dt d-none d-lg-block">
                <div class="title" i18n="@@connect-share">Connect & Share...</div>
                <div class="sub-title" i18n="@@connect-share-our-app">Our App.</div>
                <div class="description" i18n="@@slide-ultimate-tool">Your ultimate tool for sharing money<br> with your
                    loved ones. Simply download<br> our App and get started now</div>
                <div *ngIf="siteVariablesModel.language=='EN'"
                    class="store-buttons mobile-app d-inline-flex justify-content-center">
                    <a href='https://apps.apple.com/us/app/gonderal/id1448242423'><img
                            src="/assets/img/SVG/Download_on_the_App_Store_Badge_US-UK_blk_092917.svg"></a>
                    <a href='https://play.google.com/store/apps/details?id=com.gonderal.android'><img
                            src="/assets/img/SVG/google-play-badge.svg"></a>
                </div>
                <div *ngIf="siteVariablesModel.language=='TR'"
                    class="store-buttons mobile-app d-inline-flex justify-content-center">
                    <a href='https://apps.apple.com/us/app/gonderal/id1448242423'><img
                            src="/assets/img/SVG/tr_Download_on_the_App_Store_Badge_TR_blk_100217.svg"></a>
                    <a href='https://play.google.com/store/apps/details?id=com.gonderal.android'><img
                            src="/assets/img/SVG/TR_google-play-badge.svg"></a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@connect-share-our-app" class="sub-title">Our App.</div>
                <div i18n="@@slide-ultimate-tool" class="description w-100">Your ultimate tool for sharing money<br>
                    with your loved ones. Simply download<br> our App and get started now</div>
                <div *ngIf="siteVariablesModel.language=='EN'"
                    class="store-buttons mobile-app d-inline-flex justify-content-center">
                    <a href='https://apps.apple.com/us/app/gonderal/id1448242423'><img
                            src="/assets/img/SVG/Download_on_the_App_Store_Badge_US-UK_blk_092917.svg"></a>
                    <a href='https://play.google.com/store/apps/details?id=com.gonderal.android'><img
                            src="/assets/img/SVG/google-play-badge.svg"></a>
                </div>
                <div *ngIf="siteVariablesModel.language=='TR'"
                    class="store-buttons mobile-app d-inline-flex justify-content-center">
                    <a href='https://apps.apple.com/us/app/gonderal/id1448242423'><img
                            src="/assets/img/SVG/tr_Download_on_the_App_Store_Badge_TR_blk_100217.svg"></a>
                    <a href='https://play.google.com/store/apps/details?id=com.gonderal.android'><img
                            src="/assets/img/SVG/TR_google-play-badge.svg"></a>
                </div>
            </div>
        </div> -->
         <!-- menuupdate -->
        <div *ngIf="activeSlide=='contact'" id="contact" class="slide"
            style="background-image: url('/assets/img/HEROS/contact.jpg');">
            <div class="content dt d-none d-lg-block">
                <div class="title" i18n="@@connect-share">Connect & Share...</div>
                <div i18n="@@connect-share-questions" class="sub-title">Your Questions.</div>
                <div class="description" i18n="@@slide-contact">You’ll find answers to many of<br> your qustions
                    below.<br> If you still have questions...</div>
                <div class="button">
                    <a i18n="@@contact-us" [routerLink]="['/contact']" [queryParams]="{ sc: 'ask-a-question' }"
                        class="buttonDefault button-red size26">Contact Us</a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@connect-share-questions" class="sub-title">Your Questions.</div>
                <div i18n="@@slide-contact" class="description w-100">You’ll find answers to many of<br> your qustions
                    below.<br> If you still have questions...</div>
                <div class="button w-100">
                    <a i18n="@@contact-us" [routerLink]="['/contact']" [queryParams]="{ sc: 'ask-a-question' }"
                        class="buttonDefault button-red mt-4 size26">Contact Us</a>
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='partners'" id="partners" class="slide"
            style="background-image: url('/assets/img/HEROS/partners.jpg');">
            <div class="content dt d-none d-lg-block">
                <div i18n="@@connect-share" class="title">Connect & Share...</div>
                <div i18n="@@connect-share-our-partners" class="sub-title">Our Partners.</div>
                <div i18n="@@slide-partners-leading-corporations" class="description">We develop partnerships with <br>
                    leading corporations by providing <br> technology led payment solutions </div>
                <div class="button">
                    <a i18n="@@become-a-partner" [routerLink]="['/partners']" [queryParams]="{ sc: 'partner-request' }"
                        class="buttonDefault button-red mt-4 size26">Become a Partner</a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@connect-share-our-partners" class="sub-title">Our Partners.</div>
                <div i18n="@@slide-partners-leading-corporations" class="description">We develop partnerships with <br>
                    leading corporations by providing <br> technology led payment solutions </div>
                <div class="button w-100">
                    <a i18n="@@become-a-partner" [routerLink]="['/partners']" [queryParams]="{ sc: 'partner-request' }"
                        class="buttonDefault button-red mt-4 size26">Become a Partner</a>
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='businessuser'" id="businessuser" class="slide"
            style="background-image: url('/assets/img/HEROS/business.jpg');">
            <div class="content dt d-none d-lg-block">
                <div i18n="@@connect-share" class="title">Connect & Share...</div>
                <div i18n="@@connect-share-with-business" class="sub-title">With Business.</div>
                <div i18n="@@slide-business" class="description">The most effective way to speed up collecting <br> your
                    recievables and to pay your suppliers.</div>
                <div class="button">
                    <a i18n="@@how-it-works" href class="buttonDefault button-red size26" (click)="howItWorks()">How it
                        works</a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div class="title pr-2" i18n="@@connect-share">Connect & Share...</div>
                <div i18n="@@connect-share-with-business" class="sub-title">With Business.</div>
                <div i18n="@@slide-business" class="description w-100">The most effective way to speed up collecting
                    <br> your recievables and to pay your suppliers.</div>
                <div class="button w-100">
                    <a i18n="@@how-it-works" href class="buttonDefault button-red mt-4 size26"
                        (click)="howItWorks()">How it works</a>
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='paypoints'||activeSlide=='home'" id="paypoints" class="slide"
            style="background-image: url('/assets/img/HEROS/paypoints.jpg');">
            <div class="content dt d-none d-lg-block">
                <div i18n="@@connect-share" class="title">Connect & Share...</div>
                <div i18n="@@connect-share-our-locations" class="sub-title">Our Locations.</div>
                <div i18n="@@slide-partners-all-over-the-country" class="description">With 400+ locations all over
                    the<br> country, there is always a GönderAL<br> payment point near you.</div>
                <div class="button">
                    <a i18n="@@find-the-nearest" href="/pay-points" class="buttonDefault button-red size26">Find the
                        nearest</a>
                </div>
                <div class="button">
                    <a i18n="@@find-all" href="/pay-pointsall" class="buttonDefault button-red size26">Find All</a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div class="title pr-2" i18n="@@connect-share">Connect & Share...</div>
                <div i18n="@@connect-share-our-locations" class="sub-title">Our Locations.</div>
                <div i18n="@@slide-partners-all-over-the-country" class="description w-100">With 500+ locations all
                    over the<br> country, there is always a GönderAL<br> payment point near you.</div>
                <div class="button w-100">
                    <a i18n="@@find-the-nearest" href="/pay-points" class="buttonDefault button-red mt-4 size26">Find
                        the nearest</a>
                </div>
                <div class="button">
                    <a i18n="@@find-all" href="/pay-pointsall" class="buttonDefault button-red size26">Find All</a>
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='aboutus'" id="about" class="slide"
            style="background-image: url('/assets/img/HEROS/about.jpg');">
            <div class="content dt d-none d-lg-block">
                <div i18n="@@connect-share" class="title">Connect & Share...</div>
                <div i18n="@@connect-share-our-business" class="sub-title">Our Business.</div>
                <div i18n="@@slide-about-us" class="description">With fintech at the heart of our business,<br> we’re
                    all about technology,<br> people and payments </div>
                <!-- <div class="button">
                    <a i18n="@@find-the-nearest" href="/pay-points" class="buttonDefault button-red size26">Find the nearest</a>
                </div> -->
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@connect-share-our-business" class="sub-title">Our Business.</div>
                <div i18n="@@slide-about-us" class="description">With fintech at the heart of our business,<br> we’re
                    all about technology,<br> people and payments </div>
                <!-- <div class="button w-100">
                    <a i18n="@@find-the-nearest" href="/pay-points" class="buttonDefault button-red mt-4 size26">Find the nearest</a>
                </div> -->
            </div>
        </div>
        <div *ngIf="activeSlide=='documents'" id="document" class="slide"
            style="background-image: url('/assets/img/HEROS/documents.jpg');">
            <div class="content dt d-none d-lg-block">
                <div i18n="@@connect-share" class="title">Connect & Share...</div>
                <div i18n="@@connect-share-our-documents" class="sub-title">Our Documents.</div>
                <div i18n="@@slide-read-small-print" class="description">Want to read the small print?<br> Here you’ll
                    find all our documentation ready for review.<br> If you have any questions or queries feel free
                    to...</div>
                <div class="button">
                    <a i18n="@@contact-us" [routerLink]="['/contact']" [queryParams]="{ sc: 'ask-a-question' }"
                        class="buttonDefault button-red size26">Contact Us</a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@connect-share-our-documents" class="sub-title">Our Documents.</div>
                <div i18n="@@slide-read-small-print" class="description w-100">Want to read the small print?<br> Here
                    you’ll find all our documentation ready for review.<br> If you have any questions or queries feel
                    free to...</div>
                <div class="button w-100">
                    <a i18n="@@contact-us" [routerLink]="['/contact']" [queryParams]="{ sc: 'ask-a-question' }"
                        class="buttonDefault button-red mt-4 size26">Contact Us</a>
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='informationsocietyservices'" id="document" class="slide"
        style="background-image: url('/assets/img/HEROS/documents.jpg');">
        <div class="content dt d-none d-lg-block">
            <div class="title">Paylaş & Yakınlaş...</div>
            <div class="sub-title mt-2">
                <div>Bilgi Toplumu</div>
                <div class="infoSocietyTitle">Hizmetleri</div></div>
            <div class="description">Bilgi Toplumu Hizmetleri kapsamında
                yayınlanan açıklamalarımızı görüntüleyebilirsiniz.
            </div>
        </div>
        <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
            <div class="shadow d-bloc d-lg-none"></div>
            <div class="title pr-2">Paylaş & Yakınlaş...</div>
            <div class="sub-title mt-2">
                <div>Bilgi Toplumu</div>
                <div class="infoSocietyTitleResponsive">Hizmetleri</div>
            </div>
            <div class="description">Bilgi Toplumu Hizmetleri kapsamında
                yayınlanan açıklamalarımızı görüntüleyebilirsiniz.
            </div>
        </div>
       </div>
        <div *ngIf="activeSlide=='services'" id="services" class="slide"
            style="background-image: url('/assets/img/HEROS/services.jpg');">
            <div class="content dt d-none d-lg-block">
                <div i18n="@@connect-share" class="title">Connect & Share...</div>
                <div i18n="@@connect-share-our-services" class="sub-title">Our Services.</div>
                <div i18n="@@slide-provided-services" class="description">Merging mobile payment solutions<br> with pay
                    points makes life easier<br> for our clients... </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@connect-share-our-services" class="sub-title">Our Services.</div>
                <div i18n="@@slide-provided-services" class="description">Merging mobile payment solutions<br> with pay
                    points makes life easier<br> for our clients... </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='moneytransfer'" id="contact" class="slide"
            style="background-image: url('/assets/img/HEROS/transfer.jpg');">
            <div class="content dt d-none d-lg-block" *ngIf="siteVariablesModel.language == 'EN'">
                <div class="title" i18n="@@connect-share">Connect & Share...</div>
                <div i18n="@@transfer-now-header" class="sub-title">Transfer Now.</div>
                <div class="description">To Transfer money you must be a Turkish Citizen <br>and have your TCKN
                    avaliable. To proceed with a
                    <br>transfer, switch our site in Turkish language.
                </div>
                <!-- <div class="button">
                    <a href="{{siteVariablesModel.turkishHref}}/money-transfer"
                        class="buttonDefault button-red size26">Switch to Turkish</a>
                </div> -->
            </div>
            <div class="content dt d-none d-lg-block" *ngIf="siteVariablesModel.language != 'EN'">
                <div class="title" i18n="@@connect-share">Connect & Share...</div>
                <div i18n="@@transfer-now-header" class="sub-title">Transfer Now.</div>
                <div class="description">Kartınızdan dilediğiniz kişinin banka hesabına <br> para gönderebilirsiniz…
                </div>
            </div>

            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none"
                *ngIf="siteVariablesModel.language == 'EN'">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@transfer-now-header" class="sub-title">Transfer Now.</div>
                <div class="description w-100">To Transfer money you must be a Turkish Citizen <br>and have your TCKN
                    avaliable. To proceed with a
                    <br>transfer, switch our site in Turkish language.
                </div>
                <!-- <div class="button w-100">
                    <a href="{{siteVariablesModel.turkishHref}}/money-transfer"
                        class="buttonDefault button-red mt-4 size26">Switch to Turkish</a>
                </div> -->
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none"
                *ngIf="siteVariablesModel.language != 'EN'">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@connect-share" class="title pr-2">Connect & Share...</div>
                <div i18n="@@transfer-now-header" class="sub-title">Transfer Now.</div>
                <div class="description">Kartınızdan dilediğiniz kişinin banka hesabına <br> para gönderebilirsiniz…
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='payment-confirm'" id="payment-confirm" class="slide"
            style="background-image: url('/assets/img/HEROS/transfer.jpg');">
            <div class="content dt d-none d-lg-block">
                <div class="title">Teşekkürler...</div>
                <div class="sub-title">İşleminiz yapıldı.</div>
                <div class="description">Dekontunuzu indirebilir veya<br> emailinize gönderebilirsiniz.</div>
                <div class="button">
                    <a class="buttonDefault button-red size26" (click)="downloadReceiptPDFFile()">Dekontu İndir</a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div class="title pr-2">Teşekkürler...</div>
                <div class="sub-title">İşleminiz yapıldı.</div>
                <div class="description w-100">Dekontunuzu indirebilir veya<br> emailinize gönderebilirsiniz.</div>
                <div class="button w-100">
                    <a class="buttonDefault button-red mt-4 size26" (click)="downloadReceiptPDFFile()">Dekontu İndir</a>
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='payment-fail'" id="payment-fail" class="slide">
            <div class="content dt d-none d-lg-block">
                <div class="title">Üzgünüz...</div>
                <div class="sub-title">İşlem tamamlanamadı.</div>
                <div class="description">İşleminiz banka tarafından onaylanmadı.</div>
                <!-- <div  class="description">Dekontunuzu görüntüleyebilir,indirebilir <br>veya mail yoluyla alabilirsiniz.</div> -->

            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div class="title pr-2">Üzgünüz...</div>
                <div class="sub-title">İşlem tamamlanamadı.</div>
                <div class="description">İşleminiz banka tarafından onaylanmadı.</div>
                <!-- <div class="description w-100">Dekontunuzu görüntüleyebilir,indirebilir <br>veya mail yoluyla alabilirsiniz.</div> -->

            </div>
        </div>
        <div *ngIf="activeSlide=='notfound'" id="notfound" class="slide"
            style="background-image: url('/assets/img/HEROS/404_bg.jpg');">
            <div class="content dt d-none d-lg-block">
                <div i18n="@@error-404-title" class="title">Oops it's a 404 Error</div>
                <div i18n="@@error-404-sub-title" class="sub-title">Page not found.</div>
                <div i18n="@@error-404-description" class="description">Sorry we can't seem to find the page<br> you
                    wanted. Please click below to return<br> to our homepage.</div>
                <div class="button">
                    <a i18n="@@home" href="/" class="buttonDefault button-red size26">Home</a>
                </div>
            </div>
            <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
                <div class="shadow d-bloc d-lg-none"></div>
                <div i18n="@@error-404-title" class="title pr-2">Oops it's a 404 Error</div>
                <div i18n="@@error-404-sub-title" class="sub-title">Page not found.</div>
                <div i18n="@@error-404-description" class="description w-100">Sorry we can't seem to find the page<br>
                    you wanted. Please click below to return<br> to our homepage.</div>
                <div class="button w-100">
                    <a i18n="@@home" href="/" class="buttonDefault button-red mt-4 size26">Home</a>
                </div>
            </div>
        </div>
        <div *ngIf="activeSlide=='businessuser-collection'" id="businessuser-collection" class="slide">
            <!-- <div class="content dt d-none d-lg-block">
              <div  class="title">Teşekkürler...</div>
              <div  class="sub-title">İşleminiz yapıldı.</div>
              <div  class="description">Dekontunuzu indirebilir veya<br> emailinize gönderebilirsiniz.</div>
          </div> -->
            <!-- <div class="content mb d-flex flex-wrap justify-content-center d-lg-none">
              <div class="shadow d-bloc d-lg-none"></div>        <div class="title pr-2">Teşekkürler...</div>
              <div  class="sub-title">İşleminiz yapıldı.</div>
              <div class="description w-100">Dekontunuzu indirebilir veya<br> emailinize gönderebilirsiniz.</div>
              <div class="button w-100">
                  <a class="buttonDefault button-red mt-4 size26" (click)="downloadReceiptPDFFile()">Dekontu İndir</a>
              </div>
          </div> -->
        </div>
    </div> <!-- Slides end -->
</div><!-- Slider end -->